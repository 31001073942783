import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "title": "S3 Object Lambda を使って処方箋プレビューに透かしを入れる",
  "date": "2022-10-28T06:38:18.000Z",
  "slug": "entry/2022/10/28/153818",
  "tags": ["medley"],
  "hero": "./2022_10_28.png",
  "heroAlt": "AWS Lambda Power Tuning"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`こんにちは。医療プラットフォーム本部プロダクト開発室エンジニアの中畑です。主にオンライン診療・服薬指導アプリ `}<a parentName="p" {...{
        "href": "https://clinics-app.com/"
      }}>{`CLINICS`}</a>{` の開発を担当しています。`}</p>
    <p>{`今回は CLINICS アプリ内で扱う処方箋プレビューに透かし(watermark)を入れた話を紹介したいと思います。なぜ実施したのか、実装方法、パフォーマンスチューニングの 3 本立てでお送りしたいと思います。`}</p>
    <h1>{`課題と解決方針`}</h1>
    <p>{`まず、なぜ処方箋プレビューに透かしを入れることにしたのか。`}</p>
    <p>{`CLINICS では診察後に患者が希望すると、かかりつけ薬局支援システム `}<a parentName="p" {...{
        "href": "https://pharms-cloud.com/"
      }}>{`Pharms`}</a>{` を導入している調剤薬局にてオンライン服薬指導を受けることができます。その際に医療機関から処方箋の画像ファイルや PDF をアップロードし、患者は CLINICS アプリを通じて、オンライン服薬指導を受けたい調剤薬局に処方箋データを送る必要があります。`}</p>
    <img {...{
      "src": "https://pharms-cloud.com/images/media-kit/online-flow.png",
      "alt": "オンライン服薬指導の流れ",
      "width": 1092,
      "height": 236
    }}></img>
    <p>{`患者はオンライン服薬指導を予約する前に医療機関からアップロードされた処方箋画像をプレビューできます。この処方箋プレビューは原本とは扱いが異なるため、患者がアプリ内でそれぞれを明確に区別できるような対応を入れる必要があり、その手段として処方箋プレビューに透かしを入れることにしたというのが理由となります。`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "600px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/22678baba9921099d4c8b0b252db11c4/b4294/shohosen.jpg",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "50%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAKABQDASIAAhEBAxEB/8QAFwABAQEBAAAAAAAAAAAAAAAAAgABBf/EABQBAQAAAAAAAAAAAAAAAAAAAAD/2gAMAwEAAhADEAAAAezjQJx//8QAGhABAAEFAAAAAAAAAAAAAAAAASEAAhASIv/aAAgBAQABBQLWbuggpx//xAAUEQEAAAAAAAAAAAAAAAAAAAAQ/9oACAEDAQE/AT//xAAUEQEAAAAAAAAAAAAAAAAAAAAQ/9oACAECAQE/AT//xAAaEAABBQEAAAAAAAAAAAAAAAABABEgITGR/9oACAEBAAY/AnvqwiP/xAAbEAADAAIDAAAAAAAAAAAAAAAAAREQMUFRkf/aAAgBAQABPyFJdVdUM+APuCuE9dFvFH//2gAMAwEAAgADAAAAEGPP/8QAFBEBAAAAAAAAAAAAAAAAAAAAEP/aAAgBAwEBPxA//8QAFhEBAQEAAAAAAAAAAAAAAAAAARAh/9oACAECAQE/EE2f/8QAGxABAAIDAQEAAAAAAAAAAAAAAQARITGBYZH/2gAIAQEAAT8QJZLHIPl1yFT3KTjeHUIWIaUT1hDSEashxP/Z')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "処方箋プレビューの透かし対応前後",
            "title": "処方箋プレビューの透かし対応前後",
            "src": "/static/22678baba9921099d4c8b0b252db11c4/b4294/shohosen.jpg",
            "srcSet": ["/static/22678baba9921099d4c8b0b252db11c4/f93b5/shohosen.jpg 300w", "/static/22678baba9921099d4c8b0b252db11c4/b4294/shohosen.jpg 600w"],
            "sizes": "(max-width: 600px) 100vw, 600px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy",
            "decoding": "async"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <h1>{`実現・実装方法`}</h1>
    <p>{`まずは画像に透かしを入れる方法を考え、それからシステム上でどのタイミングで透かしを入れるか検討しました。前提条件ですが、処方箋プレビュー画像は以下のような形で保存されています。`}</p>
    <ul>
      <li parentName="ul">{`フォーマット: JPEG, PNG, GIF, PDF(画像以外もある)`}</li>
      <li parentName="ul">{`保存場所: S3`}</li>
    </ul>
    <h2>{`画像に透かしを入れる方法`}</h2>
    <p>{`透かしは技術的には 2 枚の画像をアルファブレンド`}<sup parentName="p" {...{
        "id": "fnref-1"
      }}><a parentName="sup" {...{
          "href": "#fn-1",
          "className": "footnote-ref"
        }}>{`1`}</a></sup>{`を使って合成した画像となります。`}</p>
    <p>{`アルファブレンドの実現方法は言語やライブラリによっていろいろありますが、今回は処方箋を扱うシステムが `}<a parentName="p" {...{
        "href": "https://go.dev/"
      }}>{`Go`}</a>{` を利用しているので、Go の以下の標準ライブラリを使って実装しました。また、画像には向き(`}<code parentName="p" {...{
        "className": "language-text"
      }}>{`orientation`}</code>{`)の情報が保持されているのですが、向きを意識しないと合成時におかしくなることがあります。端末やツールによって取り扱いが異なる部分が多いので、それを解消してくれる `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`disintegration/imaging`}</code>{` パッケージを利用しました。`}</p>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://pkg.go.dev/image"
        }}>{`image パッケージ`}</a>{`: ベースライブラリ`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://pkg.go.dev/github.com/yssk22/go/x/ximage"
        }}>{`ximage パッケージ`}</a>{`: 描画処理`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://github.com/disintegration/imaging"
        }}>{`disintegration/imaging パッケージ`}</a>{`: 読み込み時に向きを補正`}</li>
    </ul>
    <p>{`詳細は省きますが、以下のようなコードで合成できます`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "go"
    }}><pre parentName="div" {...{
        "className": "language-go"
      }}><code parentName="pre" {...{
          "className": "language-go"
        }}><span parentName="code" {...{
            "className": "token keyword"
          }}>{`func`}</span>{` `}<span parentName="code" {...{
            "className": "token function"
          }}>{`Watermark`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`srcFile `}<span parentName="code" {...{
            "className": "token builtin"
          }}>{`string`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
	`}<span parentName="code" {...{
            "className": "token comment"
          }}>{`// 元画像`}</span>{`
	imgb`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token boolean"
          }}>{`_`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`:=`}</span>{` os`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`Open`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`srcFile`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{`
	`}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`var`}</span>{` img image`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`Image
	img`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token boolean"
          }}>{`_`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` imaging`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`Decode`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`imgb`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` imaging`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`AutoOrientation`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token boolean"
          }}>{`true`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{`
	`}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`defer`}</span>{` imgb`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`Close`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{`

	`}<span parentName="code" {...{
            "className": "token comment"
          }}>{`// 上に重ねる透かし画像`}</span>{`
	wmb`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token boolean"
          }}>{`_`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`:=`}</span>{` os`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`Open`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"watermark.png"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{`
	watermark`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token boolean"
          }}>{`_`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token boolean"
          }}>{`_`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`:=`}</span>{` image`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`Decode`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`wmb`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{`
	`}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`defer`}</span>{` wmb`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`Close`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{`

	`}<span parentName="code" {...{
            "className": "token comment"
          }}>{`// 透かしを配置する場所 (画像サイズによって異なるため、実際は計算が必要)`}</span>{`
	watermarkRect `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`:=`}</span>{` image`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`Rectangle`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`image`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`Point`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`100`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`100`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` image`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`Point`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`200`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`300`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`

	`}<span parentName="code" {...{
            "className": "token comment"
          }}>{`// 合成処理（ここではバイリニア補間で実施）`}</span>{`
	b `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`:=`}</span>{` img`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`Bounds`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{`
	m `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`:=`}</span>{` image`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`NewNRGBA`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`b`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{`
	draw`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`Draw`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`m`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` b`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` img`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` image`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`ZP`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` draw`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`Src`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{`
	draw`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`BiLinear`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`Scale`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`m`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` watermarkRect`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` watermark`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` watermark`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`Bounds`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` draw`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`Over`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token boolean"
          }}>{`nil`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{`

	`}<span parentName="code" {...{
            "className": "token comment"
          }}>{`// 合成画像の出力（JPEG 出力。他のエンコーダーを利用すれば他の画像形式も可）`}</span>{`
	imgw`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token boolean"
          }}>{`_`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`:=`}</span>{` os`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`Create`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"/tmp/dest.jpg"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{`
	jpeg`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`Encode`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`imgw`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` m`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`&`}</span>{`jpeg`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`Options`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`Quality`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` jpeg`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`DefaultQuality`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{`
	`}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`defer`}</span>{` imgw`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`Close`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{`
`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span></code></pre></div>
    <h2>{`PDF に透かしを入れる方法`}</h2>
    <p>{`また、今回の要件には PDF ファイルにも透かしを入れる必要がありました。PDF にはレイヤーという概念があり、元の PDF に対して透かしを入れるレイヤーを追加すれば実現できます。Go には PDF を標準で扱えるパッケージがなかったので、今回は `}<a parentName="p" {...{
        "href": "https://pkg.go.dev/github.com/hhrutter/pdfcpu/pkg/pdfcpu"
      }}>{`pdfcpu`}</a>{` を利用しました。`}</p>
    <p>{`透かしのレイヤーを追加する関数が用意されており、以下のコードだけで実現できて大変便利です。`}<sup parentName="p" {...{
        "id": "fnref-2"
      }}><a parentName="sup" {...{
          "href": "#fn-2",
          "className": "footnote-ref"
        }}>{`2`}</a></sup></p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "go"
    }}><pre parentName="div" {...{
        "className": "language-go"
      }}><code parentName="pre" {...{
          "className": "language-go"
        }}>{`wm`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token boolean"
          }}>{`_`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`:=`}</span>{` api`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`ImageWatermark`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"watermark.jpg"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"sc:.8 rel, rot:0"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` onTop`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` update`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` pdfcpu`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`POINTS`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{`
api`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`AddWatermarksFile`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"src.pdf"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"dest.pdf"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token boolean"
          }}>{`nil`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` wm`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token boolean"
          }}>{`nil`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span></code></pre></div>
    <h2>{`S3 画像に透かしを入れる方法`}</h2>
    <p>{`AWS を利用していると S3 に各種ファイルを保存することも多いと思います。
基本的には S3 画像を読み込んで透かし処理をすれば実現できますが、AWS には S3 のレスポンスを加工するマネージメントサービスがいくつかあり、今回は `}<a parentName="p" {...{
        "href": "https://aws.amazon.com/jp/s3/features/object-lambda/"
      }}>{`S3 Object Lambda`}</a>{` を利用しました。`}</p>
    <p>{`S3 Object Lambda を利用することで S3 からファイルを取得する際に `}<a parentName="p" {...{
        "href": "https://aws.amazon.com/jp/lambda/"
      }}>{`Lambda`}</a>{` による処理を実行したうえで返却することができます。これを利用して、元の画像に Lambda で透かしを入れて返却することができます。`}</p>
    <p>{`処方箋プレビューに透かしを入れる前の構成は以下のようなイメージです。
`}<span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "391px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/1394890ffb12f712184a6a7cd2380276/14e0c/image_001.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "95%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAATCAYAAACQjC21AAAACXBIWXMAAAsTAAALEwEAmpwYAAAB4ElEQVQ4y5WU626bQBCFean+7xP0yfq3b9A3qBSpF7VKFaWtklSxsJpYDY65mASw45iFXXZZONUs2MKRBXSkFewKfZyZMzsW2tBam+fX7+d49foNrq5t3MzuMLH/YLHwkGU50pQhZf3LIkhd16iqygC9IMTJp2/Ic45CSghVocL4sAjWjbIsD/Y6ukN+ewYtGOr2533LaiAaRSGhlELKMpOakhKZkAhP3iH58BYiuEGTTb9eA6T0RFGYgzhOkKxW5l0ojfj0PcT0M2Q0x648g0ApFRhjCIIAtm1jOp3C931TQ5k9w538QMXT8cCcc5Mu5xyr9RqbzQZCCOO80hWettkeVg+ZQql+/HKKh8fYHJCqsm2hbuyVDSlkWQ7n3kWcNHUjtQTFC0e7+14gufvz4jceokYh5wKyA/zfsKihZ3/nYKypExeHwGMqBxub0iWlx4BH6zjYNkrtr14XaJq+KiHLYrzCpm4cugWa3muBhRKYeBe4WpzDXTmjjDFASpfcdZw5/GBppoZSJdzQQ/jkI9ouYfuX42u4+5C1I4hujhkMlUa0DeGt59jy5/EKux+R2p1BNChoLv66vMbtzGmd6VdpvWwL0TGFptBjFMP1l4iT9XiF3doIURy4fKxt+oD/AD5sy8/lyw4jAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "透かし対応前",
            "title": "透かし対応前",
            "src": "/static/1394890ffb12f712184a6a7cd2380276/14e0c/image_001.png",
            "srcSet": ["/static/1394890ffb12f712184a6a7cd2380276/5a46d/image_001.png 300w", "/static/1394890ffb12f712184a6a7cd2380276/14e0c/image_001.png 391w"],
            "sizes": "(max-width: 391px) 100vw, 391px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy",
            "decoding": "async"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <ol>
      <li parentName="ol">{`アプリサーバーにて処方箋プレビューの S3 presigned URL（署名付き URL）を発行`}</li>
      <li parentName="ol">{`クライアントに返す`}</li>
      <li parentName="ol">{`クライアントは受け取った URL にアクセス。処方箋プレビューが表示される`}</li>
    </ol>
    <p>{`これに S3 Object Lambda を利用して透かしを入れると以下のようになります。
`}<span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "391px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/d17d5dfd4ab59c8c34acb8aa9ed7232a/14e0c/image_002.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "95%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAATCAYAAACQjC21AAAACXBIWXMAAAsTAAALEwEAmpwYAAADFUlEQVQ4y42U224iRxCGeanc5wnyZLnNA2Sl3CXKRaSNdrXZxHi1NrFZY2MzYBhgOAzHAebc3XP4ku4hrB3Jm5TUA6rp+uuvv6qmxtHyPDe/7+sf+errb2i22li9AbftDuPxhCiKCYKQIPzyqWmQsiwpisIATmcur9/+ThwnCClJVUHB/7eaBntq2ZHpiflqQNz9QJ6G6Js6sY556RiGWZYhpCID/MMBf7dDlSWxULivv2Pzy7ekM+tZIkPk30cz1I84SRG+j9pukY+PyLGD2qxJgoh1/QfS9lvE0jYBXrhC5aoC/Qf8c5YKMBWCUimwbbi/h34fej1zMw33OHeX5GlgYn68+p7hooMWtpCCMstAxx+lqhjGCbluynJJ1rHIRyNKx9FaUIiMvecb8DxNObfe4A5uoden0ElnM8pOh3Kx0AJT0+ze/HaGu1iZDLIokDrrMeNo1eWi/47Nwf2sX+gRPvyB8mYn7Yw/TamFUYw9cthsPaSUWFaXvW5KnDBfLfj19md+un5Fo19nMp4wns1x7z7g118RdOr4mwXebo/SJKSkJoTk8uqG5WpjsiwWS0QqTLnanK1NY3DGJljiH3wSIVG7OZF1Tmw3TUuCIKgWQylq+s/ImZpNMCWrzDAthMAPQh46fXqPIyZTlyyrZJgt1rQ7Paz+yMxtmiQMhyMiz6OmB1WvlFLVKCRJipIKGcX0BkMu/vxE+6FnqtDSaNPyNK5bZi21RVHEwfcpkqTq8lNLU1GVrJujMtbbLdu9x/7gn+5IqZguZ8avLQzDakp0yU/X5gQotIY5Kpfcz5q0JpdMd6MT4HzvcDF4T3P8kaIsiKPY7L1pyrNVegqYl0QiZHmYsw5cLrp1fD8kVwX3kxsa9hnn1juGjs1hf6i+AS8BSlF1OS8LVr7LxBviJ3vzXjPSdje9wovWx9jKVwrxBYa6WWFE86ZN4/qGbs8+DfHcXWE99rGHDnrsTvv8EqBhmOdkRcFyvcGZuqy33knD4XjC5XWLVrtjvlL/DXgU2Jz8uIZZZkoyvixj4y4QYfjc/zfgXxnArIn5fZlVAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "透かし対応後",
            "title": "透かし対応後",
            "src": "/static/d17d5dfd4ab59c8c34acb8aa9ed7232a/14e0c/image_002.png",
            "srcSet": ["/static/d17d5dfd4ab59c8c34acb8aa9ed7232a/5a46d/image_002.png 300w", "/static/d17d5dfd4ab59c8c34acb8aa9ed7232a/14e0c/image_002.png 391w"],
            "sizes": "(max-width: 391px) 100vw, 391px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy",
            "decoding": "async"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <ol>
      <li parentName="ol">{`アプリサーバーにて処方箋プレビューの `}<code parentName="li" {...{
          "className": "language-text"
        }}>{`S3 Object Lambda Access Point`}</code>{` の presigned URL（署名付き URL）を発行`}</li>
      <li parentName="ol">{`クライアントは受け取った URL にアクセス`}</li>
      <li parentName="ol"><code parentName="li" {...{
          "className": "language-text"
        }}>{`S3 Object Lambda Access Point`}</code>{` は透かし処理を行う Lambda 関数を実行。その際に `}<code parentName="li" {...{
          "className": "language-text"
        }}>{`S3 Access Point`}</code>{` の署名付き URL を発行し、リクエストパラメータに設定`}</li>
      <li parentName="ol"><code parentName="li" {...{
          "className": "language-text"
        }}>{`Lambda Function`}</code>{` は受け取った URL にアクセスし処方箋プレビューの元画像を取得して透かしを入れて返却`}</li>
      <li parentName="ol">{`透かし入りの処方箋プレビューが表示される`}</li>
    </ol>
    <p>{`新たに `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`S3 Object Lambda Access Point`}</code>{`, `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`Lambda Function`}</code>{`, `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`S3 Access Point`}</code>{` を用意する必要があって少しややこしいですが簡単に説明すると以下のような役割のものとなります。`}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`icon`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`title`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><span parentName="td" {...{
              "className": "gatsby-resp-image-wrapper",
              "style": {
                "position": "relative",
                "display": "block",
                "marginLeft": "auto",
                "marginRight": "auto",
                "maxWidth": "48px"
              }
            }}>{`
      `}<a parentName="span" {...{
                "className": "gatsby-resp-image-link",
                "href": "/static/27f2d0d1b75bad44f1c560559391afdf/5ecaa/Res_Amazon-Simple-Storage-Service_General-Access-Points_48_Light.png",
                "style": {
                  "display": "block"
                },
                "target": "_blank",
                "rel": "noopener"
              }}>{`
    `}<span parentName="a" {...{
                  "className": "gatsby-resp-image-background-image",
                  "style": {
                    "paddingBottom": "100%",
                    "position": "relative",
                    "bottom": "0",
                    "left": "0",
                    "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAACXBIWXMAAAsTAAALEwEAmpwYAAADr0lEQVQ4y4VUW2tcVRReZ8amBS8t2lK06jSzvz2TtNFRUZuQnL32yfRioX3wQRSECEIVRMEbRqypTTJn7TNJS0RQFCv6oD6oKOKt9VbqBUUU9Ul88MkHf4A/ILL2OROjWFywYfaZvb79fd9eaxER0cFnQCzl8gHJVM8kLIbSXlO/NVjMOAv2eLFXprmJ55w0a75okQ+WvOad9bQWAzAWJFlhqUq6nQWnWfAxC96t1ucs+IAFh5wY6p7cTV5sMsiPceDEGGXlLUkmlmZOdfTAmyz4iAVpVrSHXD5MU70mpcev1sSMBedY8NLksQZl/VKVD6DuMohunNtB3X6LyBFNLEZmH7Kg73qlNN9vq/S6y9u6P86htS3NI6NTLHjd5aB00STZkqV0AUSjs5vJ5abuSplPsuA1/e16zSEW6LogSgqYZsEqC15gBSzPq/x7K8n1qXlD0VSVnBWtzSz4zgW71UXTNcEoEHG/tYEFX/qAPU7wHgv2RfZiR1jwdbbcrPt+vDQ+SK264TYWvFK97B0seIgF90yH4TqLVYA/K2//YMHzmpMuDuvZdypfIxatSRLMs+ABL7su1iQnZo4Fv+lrezGqpOEFV3WLkQsn5nfS+LFG4so8YcH9A9nrAU+w4G6W9jYWfFqVzjILjrDYKN8H22HBGyw4emBltF7lqe+z6wEHfzyqB299rqP7X1nwFQt+Z8He6GPA5Sz4hgMOs+DVCkjznmXBXWuAvvLQC7Qb3neLIBfMFhfMDhfMpT7YJAIKxljwbZpHj+9Tv6vKOMdi26UKm1AWWjTZa1B18LQXy3rBZLFTgckJkoFkFjzFgu9Z8EV3yVyiHcOCt8syG65NF6NEWW7Xv/SEsthbjGz05X5T9f06DrZTMjJIe4ayfvsiFvzAASOVJUnWb5PWEpV1V3rpxc6ofz6YtosdEZn1WJDHYs5BLsf1FdPDA+/KwdIq+zkV0Piiyl7rmP0sOMuClzmYlsvNYyx4nANu0HZjwWcsdoIrIloRLm/+PW1U3v6lXXTT3BXacnVlse9kR2044oOZcLmZ5YCjXOAWFszc/MT2yFSZqfy3frozdtxarK6uUrc/Stc8soUmFxplbQZLXOAyH1rXco4HWfCwD60Oly9fyuwZSheGI6H/jOmV3bHpfYEkW1LjsZ0Fn7DgZxb8woIzWYGtWVGNLG3TF6fof6OaLjV9ve6K2eQEP+rgOPj02AZf1luNz8fqfIDltFFQnTh2IxejQ74s8No/JvS/4i9yrpRU+dK9RgAAAABJRU5ErkJggg==')",
                    "backgroundSize": "cover",
                    "display": "block"
                  }
                }}></span>{`
  `}<img parentName="a" {...{
                  "className": "gatsby-resp-image-image",
                  "alt": "S3 Access Point",
                  "title": "S3 Access Point",
                  "src": "/static/27f2d0d1b75bad44f1c560559391afdf/5ecaa/Res_Amazon-Simple-Storage-Service_General-Access-Points_48_Light.png",
                  "srcSet": ["/static/27f2d0d1b75bad44f1c560559391afdf/5ecaa/Res_Amazon-Simple-Storage-Service_General-Access-Points_48_Light.png 48w"],
                  "sizes": "(max-width: 48px) 100vw, 48px",
                  "style": {
                    "width": "100%",
                    "height": "100%",
                    "margin": "0",
                    "verticalAlign": "middle",
                    "position": "absolute",
                    "top": "0",
                    "left": "0"
                  },
                  "loading": "lazy",
                  "decoding": "async"
                }}></img>{`
  `}</a>{`
    `}</span></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`S3 Access Point`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`S3 Bucket に対する alias。S3 Object Lambda は必ずこれを経由して S3 にアクセスする必要がある`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><span parentName="td" {...{
              "className": "gatsby-resp-image-wrapper",
              "style": {
                "position": "relative",
                "display": "block",
                "marginLeft": "auto",
                "marginRight": "auto",
                "maxWidth": "48px"
              }
            }}>{`
      `}<a parentName="span" {...{
                "className": "gatsby-resp-image-link",
                "href": "/static/921f353a718c60a3c3a64bcbb8da8659/5ecaa/Res_AWS-Lambda_Lambda-Function_48_Light.png",
                "style": {
                  "display": "block"
                },
                "target": "_blank",
                "rel": "noopener"
              }}>{`
    `}<span parentName="a" {...{
                  "className": "gatsby-resp-image-background-image",
                  "style": {
                    "paddingBottom": "100%",
                    "position": "relative",
                    "bottom": "0",
                    "left": "0",
                    "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAACXBIWXMAAAsTAAALEwEAmpwYAAADYUlEQVQ4y31VTYtcVRCtnn7zzn0zZkxAERQ0ZOLGqMFVshFXorgRTEz3vPN6WsWgCzeK4gd+RAkOQQOCG8UfoOLGQDBoRAIihiTOR7/zZrqJ6M61v0Ck7ruTmQ7oqt+9VXX6VNWpumZm1hA2Xs5tvQdTia4YTGVuV580E3FQzI+K+RERB1aPmfuYqhB9PcZjHcO2wTZL2IS51Q7mzgx7Rbwt4ryIb0V8I+KciAsiLoo4FX1KxJhJlUeMCOroDiZiRnQw9EWsi/hMxONNFfY3y/MLo37HJsOQibhfxBkRG8k3xjqoY9l6r2iZRUP+lohfVIWjid136beOrCJ7WMriHhFXxPwNv6tj+oW1NStvMLu2/nRmNXFAxPdrZW4jBmsY7nSbiLvqllHeVLB/vnrAWlD0I0aJrsUGtDXbUBX2q/KC45CIsYjTDfFoXcair4hYqVPdRGQOmphutBghdSwWOdbF6jLvjgdhVsSyiHdEjLaqYkEVbhdxVVVYaKpgzaDwuNkUf2a7JHbtRJSGd+5QrE8VOuPn56Js1vuZ2846Uz+L+FzEq6kRLq9O+vbYiy4pPyy6HLaGIdscBmuq0Gmqwpqq8Lr9LYIifro+yBzgoIhf/3jxtu7kmT22OSg642Gwcdt9l9SiJcGeq5cyq/tdl06WCv+uiCYx/CQya1l+LeLZxCzzGI9tdZofmQLU0mzXxdkw3OH1Gg/n5hPAKyJ+UC9zm/tfGvVzu3TcrF7KbgZsU3bRbvTzmVTk04nZdsPeF/GpiJPpzvVZ1szt8lPz0yn/ttMUnwCXxz4Rq6rC3aM2xUsiHhTxkIif63LWpfKwiL9EfFmf8HOMTU3Zkc3Z9P2miA9aJuF4TMW11zI73xBPuG1rUNzqoq57rmN8eEM2cWswRFbNoLhPxETERyIcbCTikTSWroDHRKyJ6Il43TveDMK97V3Y61i7R88Bfk9ifk/EFw3x0mrfbINZZ603Y1eOxe30ctLjiiq8IOLy1OjdtBxe825uDuf2pBTtz5P7rJVGd+b6c/Pxzm2bw/lbRPw4vRzS+hr7LtxZXy5kifhYxGEXfNToUmZbbTcPJ1uTfGPspGoX7f8t2FOp+xfScv3vBctdC3b6CSh2PQHYnvPFVvxxABbjXfun6Qkopp6AfwGcMD7F4Rj/zAAAAABJRU5ErkJggg==')",
                    "backgroundSize": "cover",
                    "display": "block"
                  }
                }}></span>{`
  `}<img parentName="a" {...{
                  "className": "gatsby-resp-image-image",
                  "alt": "Lambda Function",
                  "title": "Lambda Function",
                  "src": "/static/921f353a718c60a3c3a64bcbb8da8659/5ecaa/Res_AWS-Lambda_Lambda-Function_48_Light.png",
                  "srcSet": ["/static/921f353a718c60a3c3a64bcbb8da8659/5ecaa/Res_AWS-Lambda_Lambda-Function_48_Light.png 48w"],
                  "sizes": "(max-width: 48px) 100vw, 48px",
                  "style": {
                    "width": "100%",
                    "height": "100%",
                    "margin": "0",
                    "verticalAlign": "middle",
                    "position": "absolute",
                    "top": "0",
                    "left": "0"
                  },
                  "loading": "lazy",
                  "decoding": "async"
                }}></img>{`
  `}</a>{`
    `}</span></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Lambda Function`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`S3 のファイルに対してなんらかの処理を実施する関数`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><span parentName="td" {...{
              "className": "gatsby-resp-image-wrapper",
              "style": {
                "position": "relative",
                "display": "block",
                "marginLeft": "auto",
                "marginRight": "auto",
                "maxWidth": "48px"
              }
            }}>{`
      `}<a parentName="span" {...{
                "className": "gatsby-resp-image-link",
                "href": "/static/28fd7beff4e317a5b555ad9788b44b75/5ecaa/Res_Amazon-Simple-Storage-Service_S3-Object-Lambda-Access-Points_48_Light.png",
                "style": {
                  "display": "block"
                },
                "target": "_blank",
                "rel": "noopener"
              }}>{`
    `}<span parentName="a" {...{
                  "className": "gatsby-resp-image-background-image",
                  "style": {
                    "paddingBottom": "100%",
                    "position": "relative",
                    "bottom": "0",
                    "left": "0",
                    "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAACXBIWXMAAAsTAAALEwEAmpwYAAADh0lEQVQ4y3VVTYtcRRStbnSYcaNx40gcZua9U/06UScoxGQy6brV3boQBRE3YkA0iRElKBIUEYVx0nXqNdNKFuImqwRCEkxMokQwEgO6UIwfIbpQf4L6I+RW1+RDJ4uCR9W9p+4959R9xhhjunUrrf6wbVwomh0WTcf5hq+rCQnY4kLpden3eK9odELR1Nh+3b6Wb9bAegmsMtvfn2m4UJhOKIxEmG7dvkeIv10oz+nS725d6V6K0VjN0dzeGmg3g21bnm3sHBRGCAqx6ofYKMQpXS6UxrHUs090eaazVY3VHM1VjATYy5VlsJEQ54U4KcSPQrzsabcK0ROiL7RLQrwixA85RmNHmqsYipU409JzZecXV+aMY2E87WYhDglxISevAXzsIx7RHI3Ne3RjCprGhbLpBqkdbeGkC/PGR2wX4jch9ntWU+Pz0nSHdoPQ7hXiVyEez4XoRatuUCQso2qqAJmzn31t54W4KkRHAow7ONfYcXCuuRTmNfknIV6VaO8W4ndha4sQl31t71OhHIuGURtk5U7l298R4j0VwWlQSNUbT7woxFkhvux9sHlSaHcL8aEQ+zRXMRTLSMCCEH8Jcc5HO5s4idjqiCMSym8c8UZnJYH+KSwvCfGPEIP7396ge19LtA8I8WnCCFgw2bRnki0itN0TEpSf8qIQenZZWxdikwQ86UKpbd7rVqzunRHah5KtQnlWsa4DhtL4aGfybR2nFYVSCb/w2Kh9mxDHhfhOiKeVgqVh1RTic088eBPgDS1/5mOpXH7hY+thRywK8YJE3CXEAbVL/1BrUog/OsMZ7UZFvCixNZe5HbfsY3uiO0yinBZijxDPC3E0KTx+Hbq2CXEpV384C/WREK/nnNOK4ev2hBEWDR+tvkW98aqP1R05+S0F9REmc6idPNMZe1YV/75XV2qfK762G9V6yTZucJOxT7hBy/TYulOIr5T07lj5fcLyuUeHm7TlY0J8K3V7emeoTBIxGbvMxv7P09uxMmtcqEx/tKBC7PXES0K8KwHLQrwmEft7o2qqQ2s09n9P7xbD4Xh+Lc/KmK83XSgPZO52aZu5suvDYTkPh1uMr6GPtpWSI57K7/qKp31CRehGazVm3fF144BdzAM2VZJIVlHsdB4Gv/jYms7P0Iy9t86AvfYLiApcrXGqA6MhAcqjnk/1WE0mCwXc7qPVX0BjvV/Av/JS1i8Gngf5AAAAAElFTkSuQmCC')",
                    "backgroundSize": "cover",
                    "display": "block"
                  }
                }}></span>{`
  `}<img parentName="a" {...{
                  "className": "gatsby-resp-image-image",
                  "alt": "S3 Object Lambda Access Point",
                  "title": "S3 Object Lambda Access Point",
                  "src": "/static/28fd7beff4e317a5b555ad9788b44b75/5ecaa/Res_Amazon-Simple-Storage-Service_S3-Object-Lambda-Access-Points_48_Light.png",
                  "srcSet": ["/static/28fd7beff4e317a5b555ad9788b44b75/5ecaa/Res_Amazon-Simple-Storage-Service_S3-Object-Lambda-Access-Points_48_Light.png 48w"],
                  "sizes": "(max-width: 48px) 100vw, 48px",
                  "style": {
                    "width": "100%",
                    "height": "100%",
                    "margin": "0",
                    "verticalAlign": "middle",
                    "position": "absolute",
                    "top": "0",
                    "left": "0"
                  },
                  "loading": "lazy",
                  "decoding": "async"
                }}></img>{`
  `}</a>{`
    `}</span></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`S3 Object Lambda Access Point`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`S3 Access Point に対して Lambda 関数を実行するためのアクセスポイント`}</td>
        </tr>
      </tbody>
    </table>
    <p>{`透かし処理を実際呼び出す際は、アプリケーション側からは S3 の presigned URL から S3 Object Lambda Access Point の presigned URL に切り替えるだけなので、透かしありなしの切り替えも簡単です。`}</p>
    <p>{`S3 presigned URL:`}</p>
    <blockquote>
      <p parentName="blockquote"><code parentName="p" {...{
          "className": "language-text"
        }}>{`https://[bucket-name].s3.ap-northeast-1.amazonaws.com/prescription.jpg?[signature]`}</code></p>
    </blockquote>
    <p>{`↓
S3 Object Lambda Access Point presigned URL:`}</p>
    <blockquote>
      <p parentName="blockquote"><code parentName="p" {...{
          "className": "language-text"
        }}>{`https://[s3-object-lambda-access-point]-[account-id].s3-object-lambda.ap-northeast-1.amazonaws.com/prescription.jpg?[signature]`}</code></p>
    </blockquote>
    <p>{`S3 Object Lambda を利用することで以下のようなメリットがあります。`}</p>
    <ul>
      <li parentName="ul">{`追加のインフラ構築が不要`}
        <ul parentName="li">
          <li parentName="ul">{`既存サーバーのリソース追加や、新たに透かし処理用のサーバーを用意する必要がない。既存のアプリサーバーへの影響を軽微にできる`}</li>
          <li parentName="ul">{`Lambda が自動的にスケールを実施してくれるので負荷対策が容易`}</li>
          <li parentName="ul">{`利用した分だけの課金となる（S3 Get + Lambda + S3 Object Lambda の利用料）`}</li>
        </ul>
      </li>
      <li parentName="ul">{`透かしを入れた処方箋プレビュー画像を事前に用意する必要がない`}
        <ul parentName="li">
          <li parentName="ul">{`事前に用意するとリリース前に保持した画像に対しても処理する必要が出てくる`}</li>
          <li parentName="ul">{`(ただし、今回は特定のユーザーのみのアクセスなので不特定多数のアクセスが見込まれる場合は事前生成したほうがよいかもしれません)`}</li>
        </ul>
      </li>
    </ul>
    <p>{`もちろん画像だけではなく、テキストデータなど他のものにも利用できるので、S3 に保管した機密情報等をフィルタリングして返したい用途などにもマッチします。`}</p>
    <p>{`AWS 公式ドキュメントに詳細な解説と様々なユースケースのチュートリアルがありますので、興味がある方は是非御覧ください。`}</p>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://docs.aws.amazon.com/ja_jp/AmazonS3/latest/userguide/transforming-objects.html"
        }}>{`S3 Object Lambda を使用したオブジェクトの変換`}</a></li>
    </ul>
    <h1>{`パフォーマンス・チューニング`}</h1>
    <p>{`ここまでで、処方箋プレビューに透かしを入れる処理自体は完成しました。ここで気になってくるのがパフォーマンスについてです。今までは S3 の画像を表示するだけでしたが透かし処理に S3 Object Lambda を使うことになったため、速度と料金が気になるところです。`}</p>
    <p>{`様々なチューニングポイントがありますが、今回は Lambda のメモリ設定に焦点を絞ってお話したいと思います。`}</p>
    <h2>{`Lambda の CPU パフォーマンス`}</h2>
    <p>{`Lambda はメモリ設定しかできませんが、メモリ量に比例して CPU 性能が向上する仕組みとなっています。
`}<a parentName="p" {...{
        "href": "https://docs.aws.amazon.com/ja_jp/lambda/latest/dg/configuration-function-common.html"
      }}>{`公式ドキュメント`}</a>{`上では 1769MB あたり 1vCPU であるとされていて、MAX の 10240MB で 6vCPU 使えるとしています。実際に Lambda の vCPU の設定を調べたブログ (`}<a parentName="p" {...{
        "href": "https://www.sentiatechblog.com/aws-re-invent-2020-day-3-optimizing-lambda-cost-with-multi-threading"
      }}>{`SENTIA tech blog`}</a>{`) では以下のようになっていたそうです。`}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Memory`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`vCPUs`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`128 - 3008 MB`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`2`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`3009 - 5307 MB`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`3`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`5308 - 7076 MB`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`4`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`7077 - 8845 MB`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`5`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`8846 - 10240 MB`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`6`}</td>
        </tr>
      </tbody>
    </table>
    <p>{`ただし、メモリをいくら増やしたところで関数自体がマルチスレッド・マルチプロセス化されていない場合はパフォーマンス向上が見込めないということにもなります。適切に並列処理が実装されていれば、vCPU が増える境目で大きな性能向上が期待できます。`}</p>
    <h2>{`Lambda の料金`}</h2>
    <p>{`東京リージョン(ap-northeast-1)において、2022 年 10 月現在は以下のような料金となります。`}<sup parentName="p" {...{
        "id": "fnref-3"
      }}><a parentName="sup" {...{
          "href": "#fn-3",
          "className": "footnote-ref"
        }}>{`3`}</a></sup></p>
    <ul>
      <li parentName="ul">{`無料枠: 1 ヶ月あたり 100 万リクエスト、40 万 GB-秒(仮に 1 GB で動かしたとして 40 万秒使える)`}
        <ul parentName="li">
          <li parentName="ul">{`128 MB だと 320 万秒、 10240 MB だと 4 万秒`}</li>
        </ul>
      </li>
      <li parentName="ul">{`有料枠: 100 万リクエストあたり 0.20 USD, GB-秒あたり 0.00001666667 USD`}
        <ul parentName="li">
          <li parentName="ul">{`1 億リクエストあたり 20 USD`}</li>
          <li parentName="ul">{`128MB で 1 万秒 使うと 0.02083 USD`}</li>
          <li parentName="ul">{`1024MB で 1 万秒 使うと 0.16666 USD`}</li>
        </ul>
      </li>
      <li parentName="ul">{`上記は x86(amd64) の値段、arm だと 2 割引`}
        <ul parentName="li">
          <li parentName="ul">{`arm は CPU パフォーマンスも 2 割向上すると言われているので、可能であれば arm を選択すると更にコスパ向上が期待できる`}</li>
        </ul>
      </li>
    </ul>
    <p>{`このように見てみると、サーバーやコンテナを利用した場合はどんなに最小構成でも冗長化を考えると数十ドルはかかるので、特に頻繁に実行されない機能は Lambda によるサーバーレス化を検討すると良さそうです。`}</p>
    <p>{`また、時間あたりで料金がかかるということは、API などネットワークアクセスで時間を使った場合も料金がかかることに注意が必要です。外部 API のパフォーマンスに依存してしまうので、なるべく Lambda は CPU を使った処理が支配的になるとコスパよく利用することができます。`}</p>
    <h2>{`ベンチマーク`}</h2>
    <p>{`Lambda のパフォーマンス計測には、`}<a parentName="p" {...{
        "href": "https://github.com/alexcasalboni/aws-lambda-power-tuning"
      }}>{`AWS Lambda Power Tuning`}</a>{` を利用しました。AWS Lambda Power Tuning は Step Functions と Lambda を使って Lambda の速度とコストを計測できるツールです。`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1200px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/81f3de54309421e384e1970011046117/ddc6c/state-machine-screenshot.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "63.33333333333333%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAANCAIAAAAmMtkJAAAACXBIWXMAAAsTAAALEwEAmpwYAAABMklEQVQoz43SzUpCQRQHcN+rTS8RvUNRVATSojbRLoheoCCqhVK+gcvIalMKkkgY6tXAm9fuzJmPc2ZO6A0L0pqzm+H85uM/k+OZ5R0zY6tEUXk6/F05nlPIfNM6v29f+4kOxX7SOsThanU7X98DhOnkXzjrkAinvYvd5/2T3pnxxqEjov93zjCgOng9XKgsrjQ2hJPsmRwFHTvzt8ndZVRoyKYAKYSQAEHYTYIt9UprT+sDHMiR7HQ7cRx770NxISpuVre6pjuOnXDei8zGxegqX9tpqzYzG2tCcXbno5fjpYflmqgzs7Y6FH8t4cf5Jp1y2q8EfRJ0XiMhoiJtrHHE6u1RJU10bNGSlcZaJPqZ3BR7TW4IWgoZ6/cEPiBNdZIq6wBAgLSiP0pTpZV33//8E94y7QmC4VK/AAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "AWS Lambda Power Tuning の Step Functions",
            "title": "AWS Lambda Power Tuning の Step Functions",
            "src": "/static/81f3de54309421e384e1970011046117/c1b63/state-machine-screenshot.png",
            "srcSet": ["/static/81f3de54309421e384e1970011046117/5a46d/state-machine-screenshot.png 300w", "/static/81f3de54309421e384e1970011046117/0a47e/state-machine-screenshot.png 600w", "/static/81f3de54309421e384e1970011046117/c1b63/state-machine-screenshot.png 1200w", "/static/81f3de54309421e384e1970011046117/ddc6c/state-machine-screenshot.png 1534w"],
            "sizes": "(max-width: 1200px) 100vw, 1200px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy",
            "decoding": "async"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <p>{`Step Functions と Lambda をデプロイした上で、Step Functions の state machine に計測したい Lambda の ARN, memory 設定, 実行回数, payload を入力することでベンチマーク結果を取得できます。
実行すると最終結果に URL が出力され、その URL をブラウザで表示することで、グラフで結果とスコアボードで結果が可視化されます。また 2 つの結果を比較して表示することもできますので改善後に比較することも容易です。`}<sup parentName="p" {...{
        "id": "fnref-4"
      }}><a parentName="sup" {...{
          "href": "#fn-4",
          "className": "footnote-ref"
        }}>{`4`}</a></sup></p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1200px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/0938df3f2c442ee9bf4b04a33ac6cdc5/763a5/image_003.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "43.333333333333336%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAJCAYAAAAywQxIAAAACXBIWXMAAAsTAAALEwEAmpwYAAABt0lEQVQoz02R227aUBBF/f39mL70pWrUpEIhUqQqoWDixOYWwNjGYBt897msyjYkGWlr5jzMmn1mjPHLjInpMJ7O+Dd1mFhzBsO/3NwNO/26HXLz54HbwSM3dw/8/H3P8HHEpO2z5oxfnK7PtOZdNqI4xp8v2YU+i2CPud2zjyPCcE0U2ESnAsdfsAw2+McDYRJxOqVsvT0b12O7C9j5Idudz8YNMMrsRHqOyKQmawRJVXOqKpIyJypSjkXGIU+Jy4K0bsiqmqqqWLs+b86K2WLNcu3izN9xFmuMuqogTyCP+QjdSl9qzdcQTUOW53hBiO+57Dy/d+h6uN4eQ0iJavvKDH0+oOocpVXPBJT+VPsWQpKmKc7SZTByGVkbFqsN9ofDukZr3QOkRKcxOj2isxhdJKi6QDd1L9EgRENVFTxNV3z77vHjfok5feVpbPM8ecMQQvTAK/QKlg26HZYlPbxbS0KTJpRlhT17x7JeMS2b0bS/dHfl1qFS6lMtTGuU6geo67cvud1ha8JyVp2rkdmC2tzLUEoi5RWoadqlZzlxnHA+p52brwOFFEgpO+DzxL44u4Id/gMNfqQ9DGLWeQAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "ベンチマーク結果の可視化",
            "title": "ベンチマーク結果の可視化",
            "src": "/static/0938df3f2c442ee9bf4b04a33ac6cdc5/c1b63/image_003.png",
            "srcSet": ["/static/0938df3f2c442ee9bf4b04a33ac6cdc5/5a46d/image_003.png 300w", "/static/0938df3f2c442ee9bf4b04a33ac6cdc5/0a47e/image_003.png 600w", "/static/0938df3f2c442ee9bf4b04a33ac6cdc5/c1b63/image_003.png 1200w", "/static/0938df3f2c442ee9bf4b04a33ac6cdc5/d61c2/image_003.png 1800w", "/static/0938df3f2c442ee9bf4b04a33ac6cdc5/97a96/image_003.png 2400w", "/static/0938df3f2c442ee9bf4b04a33ac6cdc5/763a5/image_003.png 2804w"],
            "sizes": "(max-width: 1200px) 100vw, 1200px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy",
            "decoding": "async"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <h3>{`AWS Lambda Power Tuning の導入`}</h3>
    <p><a parentName="p" {...{
        "href": "https://github.com/alexcasalboni/aws-lambda-power-tuning/blob/master/README-DEPLOY.md"
      }}>{`リポジトリのドキュメント`}</a>{`に従い導入していきます。`}</p>
    <p>{`今回は `}<a parentName="p" {...{
        "href": "https://aws.amazon.com/jp/serverless/serverlessrepo/"
      }}>{`AWS Serverless Application Repository(SAR)`}</a>{` を使って導入しました。AWS Serverless Application Repository(SAR) とはその名の通り、サーバーレスアプリケーションを管理するリポジトリですが、組織内に限らず公開することも可能で、他の人も再利用可能になっています。また、導入する際は公開ページの Deploy ボタンをポチるだけで自身の AWS アカウント上にデプロイされ、 `}<a parentName="p" {...{
        "href": "https://aws.amazon.com/jp/cloudformation/"
      }}>{`CloudFormation`}</a>{` の Stack として管理されます。削除も CloudFormation の Stack を削除するだけで実施できます。`}</p>
    <p>{`AWS Lambda Power Tuning は`}<a parentName="p" {...{
        "href": "https://serverlessrepo.aws.amazon.com/applications/arn:aws:serverlessrepo:us-east-1:451282441545:applications~aws-lambda-power-tuning"
      }}>{`こちらから`}</a>{`自身のアカウントにデプロイできます。
また、AWS や個人だけでなく Datadog や New Relic など他のパブリッシャーもサーバーレスアプリケーションを公開しているので、他にも使いたいものがないか探してみるとよいかもしれません。`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1200px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/ac3cf0c3fabcf9e28233e6b42e92f91c/b5c21/image_004.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "33.33333333333333%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAHCAYAAAAIy204AAAACXBIWXMAABYlAAAWJQFJUiTwAAABbklEQVQoz43Qy4oTURDG8X4QQZfC6FqZEbe+ii/gawwuBB9A3E28jJmOdMDeuBJBcCHpa2I6ffr0OaevCYwwHf7SJzO6Elz8+AqqalHlPHj8hFt3H3Ln3gm3j06u8/j/jLP3H/11dIxz4flMZj5Tz+fDbM5718P1fOvC+4T7L3MfdzbHfTvFnZzjvpvaHScrJGkmkEqzyXOKsqRUiqvhimE/MAyDrW8MN8Ze0zCsluzFhn0UMOy2OOs0JolCgjAiCEPiJLUpyxKlNcYYuq6l63ur7Tqatj0whqauqb7/oFkKmtLgnH9b8SUS5KoizjVJXrJWDZlubW50g6h6MqnJRUHddmx3O/q+Z/vrki5JUafPKF88Rb16jnP2dcnnIEdXNdJUqDF1RWkOxlpqY18yvsJUNVV9reswi4Ds44T47CXrN68PJ6/ShEUQ2lPDMCJOEpthFLFYBH96q59rRCHZCIEoCisvCmQukYWhrhp+A7aezaaHJQJTAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "ポチるだけでインストール完了！",
            "title": "ポチるだけでインストール完了！",
            "src": "/static/ac3cf0c3fabcf9e28233e6b42e92f91c/c1b63/image_004.png",
            "srcSet": ["/static/ac3cf0c3fabcf9e28233e6b42e92f91c/5a46d/image_004.png 300w", "/static/ac3cf0c3fabcf9e28233e6b42e92f91c/0a47e/image_004.png 600w", "/static/ac3cf0c3fabcf9e28233e6b42e92f91c/c1b63/image_004.png 1200w", "/static/ac3cf0c3fabcf9e28233e6b42e92f91c/d61c2/image_004.png 1800w", "/static/ac3cf0c3fabcf9e28233e6b42e92f91c/b5c21/image_004.png 2154w"],
            "sizes": "(max-width: 1200px) 100vw, 1200px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy",
            "decoding": "async"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <h3>{`AWS Lambda Power Tuning の実行`}</h3>
    <p>{`デプロイが完了すると、AWS Step Functions 上に state machine があるので、state machine にパラメータを入力することで計測ができます。`}<a parentName="p" {...{
        "href": "https://github.com/alexcasalboni/aws-lambda-power-tuning/blob/master/README-EXECUTE.md"
      }}>{`リポジトリのドキュメント`}</a>{`にいくつかやり方が記載されています。ここでは
実行パラメータを定義した json ファイルを用意してスクリプトから実行する方法を紹介します。`}</p>
    <p>{`まずは、`}<a parentName="p" {...{
        "href": "https://github.com/alexcasalboni/aws-lambda-power-tuning"
      }}>{`AWS Lambda Power Tuning リポジトリ`}</a>{`を `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`git clone`}</code>{` し、`}<a parentName="p" {...{
        "href": "https://github.com/alexcasalboni/aws-lambda-power-tuning/blob/master/scripts/sample-execution-input.json"
      }}>{`scripts/sample-execution-input.json`}</a>{` を編集します。`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "json"
    }}><pre parentName="div" {...{
        "className": "language-json"
      }}><code parentName="pre" {...{
          "className": "language-json"
        }}><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
  `}<span parentName="code" {...{
            "className": "token property"
          }}>{`"lambdaARN"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"arn:aws:lambda:ap-northeast-1:123456789012:function:lambda-performance-tuning"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
  `}<span parentName="code" {...{
            "className": "token property"
          }}>{`"powerValues"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`128`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`256`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`512`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`832`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`1024`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`1536`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`1769`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`1770`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`2048`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`3008`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
  `}<span parentName="code" {...{
            "className": "token property"
          }}>{`"num"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`100`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
  `}<span parentName="code" {...{
            "className": "token property"
          }}>{`"payload"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
    `}<span parentName="code" {...{
            "className": "token property"
          }}>{`"body"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"{\\"id\\": \\"123\\",\\"name\\":\\"performance\\"}"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
    `}<span parentName="code" {...{
            "className": "token property"
          }}>{`"path"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"/api/performance"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
    `}<span parentName="code" {...{
            "className": "token property"
          }}>{`"httpMethod"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"GET"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
    `}<span parentName="code" {...{
            "className": "token property"
          }}>{`"isBase64Encoded"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token boolean"
          }}>{`false`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
    `}<span parentName="code" {...{
            "className": "token property"
          }}>{`"multiValueHeaders"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{` `}<span parentName="code" {...{
            "className": "token property"
          }}>{`"Accept"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"application/json"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`
  `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
  `}<span parentName="code" {...{
            "className": "token property"
          }}>{`"parallelInvocation"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token boolean"
          }}>{`false`}</span>{`
`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span></code></pre></div>
    <p>{`入力値の詳細は以下の通りです。`}<sup parentName="p" {...{
        "id": "fnref-5"
      }}><a parentName="sup" {...{
          "href": "#fn-5",
          "className": "footnote-ref"
        }}>{`5`}</a></sup></p>
    <ul>
      <li parentName="ul">{`lambdaARN: Lambda Function の ARN`}</li>
      <li parentName="ul">{`powerValues: 計測したい Lambda のメモリを指定`}</li>
      <li parentName="ul">{`num: メモリごとの Lambda 実行回数`}</li>
      <li parentName="ul">{`payload: Lambda Function にわたす Event JSON の内容`}</li>
      <li parentName="ul">{`parallelInvocation: ベンチマークを並列実行する`}</li>
    </ul>
    <p>{`次に、 `}<a parentName="p" {...{
        "href": "https://github.com/alexcasalboni/aws-lambda-power-tuning/blob/master/scripts/execute.sh"
      }}>{`scripts/execute.sh`}</a>{` を実行すると、ベンチマークが開始され、最後に結果 URL が出力されます。デプロイ時の設定によって、CloudFormation の Stack Name が違う場合もありますので、必要に応じて書き換えてください。`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "bash"
    }}><pre parentName="div" {...{
        "className": "language-bash"
      }}><code parentName="pre" {...{
          "className": "language-bash"
        }}>{`$ `}<span parentName="code" {...{
            "className": "token function"
          }}>{`sh`}</span>{` ./scripts/execute.sh
-n Execution started`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`..`}</span>{`.
-n `}<span parentName="code" {...{
            "className": "token builtin class-name"
          }}>{`.`}</span>{`
`}<span parentName="code" {...{
            "className": "token builtin class-name"
          }}>{`:`}</span>{`
-n `}<span parentName="code" {...{
            "className": "token builtin class-name"
          }}>{`.`}</span>{`
SUCCEEDED
Execution output:
`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"power"`}</span>{`:128,`}<span parentName="code" {...{
            "className": "token string"
          }}>{`"cost"`}</span>{`:8.4E-9,`}<span parentName="code" {...{
            "className": "token string"
          }}>{`"duration"`}</span>{`:3.6706666666666674,`}<span parentName="code" {...{
            "className": "token string"
          }}>{`"stateMachine"`}</span>{`:`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"executionCost"`}</span>{`:4.0E-4,`}<span parentName="code" {...{
            "className": "token string"
          }}>{`"lambdaCost"`}</span>{`:1.0178708203125003E-4,`}<span parentName="code" {...{
            "className": "token string"
          }}>{`"visualization"`}</span><span parentName="code" {...{
            "className": "token builtin class-name"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"https://lambda-power-tuning.show/#gAAAAQACQAMABAAG6QbqBgAIwAs=;NOxqQP9GmkDFII5AbxKBQLErfECGXW1AWDmGQNNNjkBpSo1ARf1nQA==;l08QMn1jtDJ9YzQz1pCSM5dPkDNjd9gzb9AbNPzmGzR9YzQ05vRTNA=="`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span></code></pre></div>
    <p>{`計測方法は以上、簡単ですね 👌`}</p>
    <h3>{`AWS Lambda Power Tuning の結果確認`}</h3>
    <p>{`計測結果を確認します。関数によって傾向は異なりますが、ここでは 2 種類のパターンを紹介します。`}</p>
    <h4>{`DB や API アクセスなど外部処理が支配的なケース(Network-intensive)`}</h4>
    <p>{`Lambda 内の処理が外部処理で占めている場合は、`}<a parentName="p" {...{
        "href": "https://lambda-power-tuning.show/#gAAAAQACQAMABAAG6QbqBgAIwAs=;NOxqQP9GmkDFII5AbxKBQLErfECGXW1AWDmGQNNNjkBpSo1ARf1nQA==;l08QMn1jtDJ9YzQz1pCSM5dPkDNjd9gzb9AbNPzmGzR9YzQ05vRTNA=="
      }}>{`以下のようなグラフ`}</a>{`になります。
赤色の実行時間がどのメモリ設定でも 4ms 前後と大きな変化がなく、青色のコストだけが右肩上がりになっています。
この場合は、Lambda の最低メモリである 128MB が最適ということになります。`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1200px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/aa7927c17fbb4fffb5f969408de68d54/baa75/image_005.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "52.33333333333333%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAKCAYAAAC0VX7mAAAACXBIWXMAABYlAAAWJQFJUiTwAAABb0lEQVQoz12SWY4bMQxE+/63ywHyl59g3N22Nmqh+ALKbo8nAggBBFVVrNI256T3jplhpWJjYKrY7z/II1CrsEfh199GKkKRQhHheYw+IWQhp7QwNlVFRLBUmNErM0Nmto71wXQis/WY3iELxIyFTLgF7qkTUuY4Dhxr8wdyD5hUJjAxpqvML5JHWgT2SNR7JsRGaspRlCgDUiHfHxzniW+7+bpVqvOvhpcrWuB+zwlmtKHsMqlq5D5R72PrXY6R8zyXdVsI7lN9grwAnyDf1XXylfVH395zRkqJfd+JMbK5f622N6APeLmK1CaxKqfoUvSc+UnsvVLK8tCxNm/KSs0WcxuTQ5RQldx0+bWC+QD5H/BSuDzUMRCplG7c61xqms4XwTNdeyV9AXyWH1d4XqGoDvZ7IMgglooTjN5pvTNUaa2t3rp1LOOvcu/9/sxh45Uuay19r+J/qhRh3w9u+87X7UYuZW1zzYwx3rPX+QeGqBBazMOyXAAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "Network-intensive",
            "title": "Network-intensive",
            "src": "/static/aa7927c17fbb4fffb5f969408de68d54/c1b63/image_005.png",
            "srcSet": ["/static/aa7927c17fbb4fffb5f969408de68d54/5a46d/image_005.png 300w", "/static/aa7927c17fbb4fffb5f969408de68d54/0a47e/image_005.png 600w", "/static/aa7927c17fbb4fffb5f969408de68d54/c1b63/image_005.png 1200w", "/static/aa7927c17fbb4fffb5f969408de68d54/baa75/image_005.png 1746w"],
            "sizes": "(max-width: 1200px) 100vw, 1200px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy",
            "decoding": "async"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <h4>{`CPU 実行時間が支配的なケース(CPU-intensive)`}</h4>
    <p>{`Lambda 内の処理が内部処理で占めている場合は、`}<a parentName="p" {...{
        "href": "https://lambda-power-tuning.show/#gAAAAQACQAMABAAG6QbqBgAIwAs=;+U9pRV6q0kTBq4NEiuI7ROmlIkQbFyNEV/cMRKk3DESOaAxELLkKRA==;ioUDNwub7TbsiRQ3+zYsNzN9NzciCoo3r0+JN3moiDddZ54398HlNw=="
      }}>{`以下のようなグラフ`}</a>{`になります。透かし処理は画像合成処理に多くの CPU を利用するので、こちらのパターンとなりました。
メモリが少ないと速度が出ておらず、メモリを増やすに連れて速度も改善しており、コストの増加も緩やかです。ただし、今回はプログラムがマルチスレッド・マルチプロセス対応はしていないため、コア数が増える 1770MB 以降は大きな速度改善は見られず、コストが上がる結果となっています。
よって、このケースではコストだけを考えたら低メモリが有利ですが、速度も考えたら 1024MB ~ 1770MB あたりにすることを検討すると良さそうです。`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1200px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/04b0fc8fe921e8cc228c0e31e01f11f3/baa75/image_006.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "52.33333333333333%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAKCAYAAAC0VX7mAAAACXBIWXMAABYlAAAWJQFJUiTwAAABaklEQVQoz3VSSY7cMAz0/3+Xc445BZ0et62FkrhUUrQH0wkQG4QokSwWl22tBekd4Y6IQP6qiO8/IK8DYwieRfDt50RtHb01iEj6ugeAgKmitZZv21JFP05EFzjNHvBwpCsT3II3/ROMp3pg7wtzDLg7AVdmjdLy4XL2i/H7PTJdnrwzAe+7OOpYaK3CyZD0+xB47fC1smQGXHKXFYFljr4caleSoY6PbqjT4abovUNVsR3HASHdpfCz3aWSwRcLAjG4Lccuhg8xnMMTlHa2rZSC8zyxEVmyf4GoHdEE0xxFA00jAwnyd8kXawrLJLMxRsrGCZXaMugYjtdLcDxOtKOj1gFZVy/9P8IhclNILKdsZrk2zERmLBJs+piADKA0xHlL6vVLv21WW65NTpmoHAyzrDWTfupmULPsj5pirglzS9unz5iXP8FIjN92Ebro8/Fd712w7y/8ej7xeDxQSs3k//oTNHf1D+Bv+9YRcdKaj3MAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "CPU-intensive",
            "title": "CPU-intensive",
            "src": "/static/04b0fc8fe921e8cc228c0e31e01f11f3/c1b63/image_006.png",
            "srcSet": ["/static/04b0fc8fe921e8cc228c0e31e01f11f3/5a46d/image_006.png 300w", "/static/04b0fc8fe921e8cc228c0e31e01f11f3/0a47e/image_006.png 600w", "/static/04b0fc8fe921e8cc228c0e31e01f11f3/c1b63/image_006.png 1200w", "/static/04b0fc8fe921e8cc228c0e31e01f11f3/baa75/image_006.png 1746w"],
            "sizes": "(max-width: 1200px) 100vw, 1200px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy",
            "decoding": "async"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <h1>{`まとめ`}</h1>
    <p>{`処方箋プレビューに透かしを入れることになった背景、実現方法を紹介いたしました。`}</p>
    <ul>
      <li parentName="ul">{`透かし処理はアルファブレンド処理を実施することで実現でき、PDF については透かし用のレイヤーを重ねることで実現できます。`}</li>
      <li parentName="ul">{`S3 Object Lambda を利用することで、追加のサーバー構築をすることなく、S3 のファイルに対してフィルタリングをかけることができます。`}</li>
      <li parentName="ul">{`Lambda のパフォーマンス・チューニングについては、AWS Lambda Power Tuning を利用して可視化できます。外部 API などネットワーク依存(Network-intensive)ではなく、CPU 処理が中心(CPU-intensive)になると、コスパよく利用することができます。`}</li>
    </ul>
    <p>{`S3 のファイルに対してなんらかの処理を実施したいと考えてる方は、一度 S3 Object Lambda の利用を検討してみてはいかがでしょうか？`}</p>
    <p>{`また、Lambda のチューニングポイントは色々ありますが、AWS Summit Online で紹介された以下のセッションがおすすめなので、もっと深くチューニングしたい方は是非ご覧ください！`}</p>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://aws.amazon.com/jp/summits/japan/sessions/?aws-summit-japan-2022-cards.sort-by=item.additionalFields.sortOrder&aws-summit-japan-2022-cards.sort-order=asc&awsf.level=*all&awsf.session-category=*all&awsf.company-category=*all&awsf.industry=*all&awsf.use-case=*all&aws-summit-japan-2022-cards.q=Lambda%2BPerformance%2BTuning%2BDeep%2BDive&aws-summit-japan-2022-cards.q_operator=AND"
        }}>{`AWS Lambda Performance Tuning Deep Dive〜本当に知りたいのは”ここ”だった〜`}</a></li>
    </ul>
    <h1>{`さいごに`}</h1>
    <p>{`メドレーでは、医療分野の社会課題を IT にて解決するために日々邁進しております。医療という分野においては、機微な情報を扱ったり診療という大事な業務を止めないよう、可用性、パフォーマンス、セキュリティともに高いサービスレベルを求められます。興味がある方は是非ご連絡ください。`}</p>
    <p><a parentName="p" {...{
        "href": "https://www.medley.jp/jobs/"
      }}>{`https://www.medley.jp/jobs/`}</a></p>

    <div {...{
      "className": "footnotes"
    }}>
      <hr parentName="div"></hr>
      <ol parentName="div">
        <li parentName="ol" {...{
          "id": "fn-1"
        }}>
          <small>元画像に直接文字や図形などを描画することももちろん可能ですが、透かしを画像データとしてデザイン、管理したいこともあり、今回はアルファブレンドを選択した。<a href="https://ja.wikipedia.org/wiki/%E3%82%A2%E3%83%AB%E3%83%95%E3%82%A1%E3%83%96%E3%83%AC%E3%83%B3%E3%83%89">アルファブレンド - wikipedia</a></small>
          <p parentName="li"><a parentName="p" {...{
              "href": "#fnref-1",
              "className": "footnote-backref"
            }}>{`↩`}</a></p>
        </li>
        <li parentName="ol" {...{
          "id": "fn-2"
        }}>
          <small>pdfcpu API document: <a href="https://pkg.go.dev/github.com/pdfcpu/pdfcpu/pkg/api#example-AddWatermarksFile">watermark example</a></small>
          <p parentName="li"><a parentName="p" {...{
              "href": "#fnref-2",
              "className": "footnote-backref"
            }}>{`↩`}</a></p>
        </li>
        <li parentName="ol" {...{
          "id": "fn-3"
        }}>
          <small>ただし最初の 60 億 GB 秒/月。昨今は円安なのでドル建てで利用しているインフラコストも上がっており、USD 表記だとコストが変わらないようにみえるので要注意。<a href="https://aws.amazon.com/jp/lambda/pricing/">AWS Lambda 料金</a></small>
          <p parentName="li"><a parentName="p" {...{
              "href": "#fnref-3",
              "className": "footnote-backref"
            }}>{`↩`}</a></p>
        </li>
        <li parentName="ol" {...{
          "id": "fn-4"
        }}>
          <small>この UI は <a href="https://github.com/matteo-ronchetti/aws-lambda-power-tuning-ui">AWS Lambda Power Tuning UI</a> というツールで別途提供されており、 `lambda-power-tuning.show` ドメインでアクセスできるので、自前で用意する必要はない。URL は `https://lambda-power-tuning.show/#AAEAAgADAAQ=;xKDwRUqaakU5+RtFcXLqRA==;UqkHOPJCBDjA6AM46DAEOA==;AAEAAgADAAQ=;aYP6RdyeeUUQKiVFuC76RA==;ZDoNOJy3DDiJrQs4zhENOA==;100;50` のような形式でデータが URL のパスパラメータにエンコードされている。</small>
          <p parentName="li"><a parentName="p" {...{
              "href": "#fnref-4",
              "className": "footnote-backref"
            }}>{`↩`}</a></p>
        </li>
        <li parentName="ol" {...{
          "id": "fn-5"
        }}>
          <small>この他にも `strategy` など様々なパラメータがある: aws-lambda-power-tuning: <a href="https://github.com/alexcasalboni/aws-lambda-power-tuning/blob/master/README-INPUT-OUTPUT.md#user-content-state-machine-input">README-INPUT-OUTPUT.md</a></small>
          <p parentName="li"><a parentName="p" {...{
              "href": "#fnref-5",
              "className": "footnote-backref"
            }}>{`↩`}</a></p>
        </li>
      </ol>
    </div>
    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      